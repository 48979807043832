<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>
          <h3>
            <i class="fas fa-envelope" />
            Wiadomość (Wysyła Wiadomość do klienta)
          </h3>
        </b-card-title>
      </b-card-header>
    </b-card>
    <b-card>
      <div class="form-group">
        <label for="title">Zawartość wiadomości: </label>
        <textarea
          id="title"
          v-model="message.content"
          class="form-control"
        />
      </div>
    </b-card>

    <b-card>
      <button
        class="btn btn-primary w-100"
        @click="sendMessage()"
      >
        Wyślij
      </button>
    </b-card>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'

import {
  BCard, BCardText, BImg, BForm,
} from 'bootstrap-vue'

export default {
  name: 'Clients',
  components: {
    BCard,
    BCardText,
    BImg,
    BForm,
  },
  data() {
    return {
      message: {
        title: '',
        content: '',
      },
      errors: [],
    }
  },
  mounted() {
    document.title = 'Wiadomości - Pickmdoe'
  },
  methods: {
    sendMessage() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios.get(`${process.env.VUE_APP_API_URL}Profile/${this.$route.params.id}`, config)
        .then(response => {
          this.message.number = response.data.success.phone_number
          this.ApiCall()
        }).catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    ApiCall() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      const postData = {
        title: this.message.title,
        content: this.message.content,
        number: this.message.number,
      }

      axios.post(`${process.env.VUE_APP_API_URL}Send-SMS`, postData, config)
        .then(response => {
          swal.fire({
            type: 'success',
            title: 'Message Sent!',
            text: 'Message sent successfully!',
            icon: 'success',
          })

          this.$router.push('/Wiadomosci')
        })
        .catch(error => {
          swal.fire({
            type: 'error',
            title: 'Oops...',
            icon: 'error',
            text: 'Coś poszło nie tak... Skontaktuj się z administratorem!',
          })
        })
    },
  },
}
</script>

<style>

</style>
